<!-- Detalle Presupuestos Funerarias Sucursales -->

<template>
  <div class="pres_exp_detalle">
    <!-- Base Detalle -->
    <v-row no-gutters>
      <v-col md="9">
        <base_D
          ref="base_D"
          :schema="schema"
          :id="id"
          :accion="accion"
          :edicion="edicion"
          :Entorno="Entorno"
          @onEvent="event_capture"
        >
          <!-- Slot campos del grid -->
          <template v-slot:fields_grid={Sitem,Sheader}>
            {{ get_field(Sitem, Sheader) }}
          </template>

          <!-- Slot panel expansible -->
          <template v-slot:expansible_ctrls="{}">
            <v-row no-gutters>
              <v-col md="1">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.clave.value"
                  :label="schema.ctrls.clave.label"
                  readonly
                  :disabled="!is_disabled"
                >
                </v-text-field>
              </v-col>

              <v-col md="5">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.name_prod_id.comp.valueaux"
                  :label="schema.ctrls.name_prod_id.label"
                  readonly
                  :disabled="!is_disabled"
                >
                </v-text-field>
              </v-col>

              <v-col md="2">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.und.value"
                  :label="schema.ctrls.und.label"
                  :disabled="!is_disabled"
                  @keyup="recalc_imp(schema)"
                >
                </v-text-field>
              </v-col>

              <v-col md="2">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.iva.value"
                  :label="schema.ctrls.iva.label"
                  :disabled="!is_disabled"
                >
                </v-text-field>
              </v-col>

              <v-col md="2">
                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.pvp.value"
                  :label="schema.ctrls.pvp.label"
                  :disabled="!is_disabled"
                  @onEvent="event_capture($event, schema)"
                >
                </v-input_n>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col md="4">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.namea.value"
                  :label="schema.ctrls.namea.label"
                  :disabled="!is_disabled"
                >
                </v-text-field>
              </v-col>

              <v-col md="2">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.grup.value"
                  :label="schema.ctrls.grup.label"
                  :disabled="!is_disabled"
                >
                </v-text-field>
              </v-col>

              <v-col md="2">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.sup.value"
                  :label="schema.ctrls.sup.label"
                  :disabled="!is_disabled"
                >
                </v-text-field>
              </v-col>

              <v-col md="2">
                <v-input_n
                  v-bind="$input"
                  v-model="schema.ctrls.imp.value"
                  :label="schema.ctrls.imp.label"
                  readonly
                  :disabled="!is_disabled"
                >
                </v-input_n>
              </v-col>
            </v-row>
          </template>
        </base_D>
      </v-col>

      <v-col md="3">
        <productos :edicion="is_disabled" @onEvent="event_capture"></productos>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mixinFinder } from "@/mixins/mixinFinder.js";

import plugs from "@/common/general_plugs";
const base_D = () => plugs.groute("base_D.vue", "base");
const productos = () => plugs.groute("productos.vue", "comp");

export default {
  mixins: [mixinFinder],
  components: { base_D, productos },
  props: {
    id: { type: String, default: "" },
    accion: { type: [Number, String], default: "" },
    edicion: { type: Boolean, default: false },
    Entorno_F: {
      type: Object,
      default: function() {
        return JSON.parse(JSON.stringify(this.$cfg.base.F));
      }
    }
  },

  data() {
    return {
      Entorno: this.Entorno_F,
      schema: null,
      selected: []
    };
  },

  computed: {
    // devuelvo si el detalle está en edición
    is_disabled() {
      return this.Entorno.estado === "editar" || this.Entorno.estado === "nuevo"
        ? true
        : false;
    }
  },

  methods: {
    // gestor de eventos
    event_capture(evt, data) {
      console.log("*** onEvent pres_exp_detalle. Accion: ", evt, " ***");

      // obtengo la acción a ejecutar (0:'ver', 1:'nuevo', 2:'editar', 3:'borrar', 4:'guardar', 5:'cancelar', 6:'salir')
      let A_acciones = [
        "ver",
        "nuevo",
        "editar",
        "borrar",
        "guardar",
        "cancelar",
        "salir"
      ];
      let accion = /^([0-9])*$/.test(evt.accion)
        ? A_acciones[evt.accion]
        : evt.accion;

      if (this.$isFunction(this[accion])) {
        this[accion](evt, data);
        return;
      }
      this.$emit("onEvent", evt);
    },

    // configuro entorno Finder particular
    get_entorno_Finder() {
      this.Entorno.api = "exp_PresupLin_F";
      if (typeof this.Entorno.grid.headers.headers === "undefined") {
        this.Entorno.grid.headers = [
          { text: "CONCEPTO", value: "name_prod_id" },
          { text: "UDS", value: "und" },
          { text: "P/T", value: "grup" },
          { text: "S", value: "sup" },
          { text: "IVA", value: "iva" },
          { text: "PRECIO", value: "pvp" },
          { text: "IMPORTE", value: "imp" }
        ].map(header => ({ ...this.Entorno.grid.headers, ...header }));
      }

      this.Entorno.header.style += ";min-width:60rem";
    },

    // configuración particular de los campos del grid
    get_field(data) {
      if (data.Sheader.value === "sp")
        return data.Sitem["sp"] === "1" ? "S" : "N";
      return data.Sitem[data.Sheader.value];
    },

    // recalculo importe linea detalle
    recalc_imp(schema_detalle) {
      schema_detalle.ctrls.imp.value = (
        schema_detalle.ctrls.und.value * schema_detalle.ctrls.pvp.value
      ).toFixed(2);
    },

    /* copiar() 
            {
                if (this.$refs.base_D.selected.length<= 0) return;
                
                this.fila_copiada= this.$refs.base_D.selected[0];
                this.$root.$alert.open('Copio fila', 'info', 1000);
            }, */

    /* pegar() 
            {
                if (!this.fila_copiada) return;
                if (this.Entorno.estado!=='nuevo') return;

                this.record2ctrl(this.fila_copiada, this.$refs.base_D.schema_detalle.ctrls);
                this.$root.$alert.open('Fila pegada', 'info', 1000);
            }, */

    change_input_n(evt, data) {
      this.recalc_imp(data);
    },

    //
    select_producto(evt) {
      this.$refs.base_D.schema.ctrls.clave.value = evt.item.clave;
      this.$refs.base_D.schema.ctrls.name_prod_id.value = evt.item.id;
      this.$refs.base_D.schema.ctrls.name_prod_id.comp.valueaux =
        "(" + evt.item.clave + ") " + evt.item.name;
    }
  }
};
</script>
